import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import ImageCell, { FormRule } from "@/components/ImageCell";
import SvgIcon from "@/components/SvgIcon";
import { SysMonth } from "@/models/select";
import { SmsSubjectAndChapterDto, SmsSubjectChapterDtos } from "@/models/subject";
import { ButtonListRow, TableRow } from "@/models/type";
import { debounceClick, getFile, parseTime, typeWatch } from "@/utils";
import { ElNotification } from "element-plus";
import { h } from "vue";
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import Subject from ".";


interface SubjectDetailElement extends JSX.Element {
  title?: string
}

@Options({})
export default class SubjectDetail extends Vue {
  @Prop(String) readonly title!: string

  public mainImgBool = false
  public coverImgBool = false
  public otherImgBool = false

  private monthProps: any = {}

  public monthList: Array<SysMonth> = []

  private tableList: Array<TableRow> = [
    { label: '时间', slot: {
      default: (scope: any): JSX.Element => <span>{ parseTime(new Date(scope.row.time).getTime(), '{y}-{m}-{d}') }</span>
    }},
    { label: '章节名称', prop: 'title' },
    { label: '章节积分', prop: 'integral' },
    { label: '图片', slot: {
      default: (scope: any): JSX.Element => <img
        class="pointer"
        style={{
          width: '50px'
        }}
        src={ scope.row.pic }
        alt={ scope.row.title }
        onClick={() => this.imgShow(scope.row.pic)}
      />
    } },
    { label: '描述', slot: {
      default: (scope: any): JSX.Element => <span>{scope.row.introduce}</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <span class="span-button-primary" onClick={ () => this.chapterEdit(scope) }>编辑</span>
    } }
  ]

  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'chapterAdd' },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'chapterDelete' },
  ]

  public subjectForm: SmsSubjectAndChapterDto = {
    title: '',
    typeId: undefined,
    tutorId: '',
    identity: '',
    identityList: [],
    introduce: '',
    peopleNumber: 0,
    price: 0,
    subjectTime: [],
    signUpTime: [],
    smsSubjectChapterDtos: [],
    mainImg: '',
    coverImg: '',
    otherImg: '',
    monthId: [],
  }

  public formRule: any = {
    typeId: [
      { required: true, message: '请选择课程类型', trigger: 'change' }
    ],
    tutorId: [
      { required: true, message: '请选择导师', trigger: 'change' }
    ],
    subjectTime: [
      { required: true, message: '请选择课程时间', trigger: 'change' }
    ],
    monthId: [
      { required: true, message: '请选择月份', trigger: 'change' }
    ],
    signUpTime: [
      { required: true, message: '请选择报名时间', trigger: 'change' }
    ],
    introduce: [
      { required: true, message: '请填写课程介绍' }
    ]
  }


  private ids: Array<SmsSubjectChapterDtos> = []
  private detailBool = false
  private detailIndex = 0
  private chapterType = ''
  private chapterTitle = ''
  private chapterForm: SmsSubjectChapterDtos = {
    subjectId: '',
    title: '',
    time: '',
    integral: '',
    introduce: '',
    pic: ''
  }
  public srcNum =0
  @Watch('srcNum')
  srcNumChange(val): void {
    if(val) {
      this.imgViewerShow()
    }
  }
  private chapterRules: any = {
    title: [
      { required: true, message: '请输入章节名称' }
    ],
    time: [
      { required: true, message: '请选择章节时间', trigger: 'change' }
    ],
    integral: [
      { required: true, message: '请输入章节积分' }
    ],
    introduce: [
      { required: true, message: '请输入章节描述' }
    ],
    pic: [
      { required: true, message: '请输入章节封面图' }
    ]
  }

  public loading = false
  public url = ''
  public srcList: Array<string> = []

  imgShow(url: string): void {
    this.url = url
    this.srcList = this.subjectForm.smsSubjectChapterDtos?.map(item => item.pic) as string[] // 将类型为图片的给srcList
    this.srcNum++
  }

  buttonClick(click: string): void {
    this[click]()
  }

  chapterAdd(): void {
    this.chapterType = 'add'
    this.detailBool = true
  }

  chapterEdit({row, $index}: { row: any, $index: number }): void {
    this.chapterForm = Object.assign({}, row)
    this.detailIndex = $index
    this.chapterType = 'edit'
    this.detailBool = true
  }

  chapterDelete(): void {
    if(!(this.ids.length > 0)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的章节列表'
      })
      return
    }

    this.ids.forEach(item => {
      this.subjectForm.smsSubjectChapterDtos?.splice(this.subjectForm.smsSubjectChapterDtos.findIndex(row => row === item), 1)
    })
  }

  /**
   * @description 返回
   * @author Jinx
   * @date 2021-08-23 10:33:26
   * @memberof SubjectDetail
   */
  goBack(): void {
    const __parent = this.$parent as Subject
    __parent.type = ''
    this.subjectForm = {
      tutorId: '',
      title: '',
      typeId: undefined,
      identity: '',
      identityList: [],
      peopleNumber: 0,
      price: 0,
      subjectTime: [],
      signUpTime: [],
      mainImg: '',
      coverImg: '',
      otherImg: '',
      introduce: '',
      smsSubjectChapterDtos: [],
      monthId: [],
    }
  }

  onChangeImg(form: FormRule): void {
    this.subjectForm.coverImg = form.coverImg
    this.subjectForm.mainImg = form.mainImg
    this.subjectForm.otherImg = form.otherImg
  }

  detailDom(): JSX.Element {
    const __parent = this.$parent as Subject

    return <div class="subject-detail">
      <el-row gutter={ 50 }>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="subject-detail-cell">
          <el-form-item label="系列课名称" prop="title">
            <el-input v-model={ this.subjectForm.title } clearable placeholder="请输入系列课名称" />
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="subject-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_1" />
              <span>课程类型</span>
            </>}}
            prop="typeId"
          >
            <el-select v-model={ this.subjectForm.typeId } clearable placeholder="请选择课程类型" filterable style="width: 100%">
              {__parent.classList.map(item => (
                <el-option label={ item.name } value={ item.value } />
              ))}
            </el-select>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="subject-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_2" />
              <span>导师</span>
            </>}}
            prop="tutorId"
          >
            <el-select v-model={ this.subjectForm.tutorId } clearable placeholder="请选择导师" filterable style="width: 100%">
              {__parent.teacherList.map(item => (
                <el-option label={ item.name } value={ item.value } />
              ))}
            </el-select>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm= { 24 } class="subject-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_4" />
              <span>报名身份</span>
            </>}}
            prop="identityList"
          >
            <el-select
              v-model={ this.subjectForm.identityList }
              clearable
              multiple
              collapse-tags
              placeholder="请选择活动身份"
              filterable
              style="width: 100%"
            >
              {__parent.memberLevelList.map(item => (
                <el-option label={ item.name } value={ item.value } />
              ))}
            </el-select>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm= { 24 } class="subject-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_3" />
              <span>报名时间</span>
            </>}}
            prop="signUpTime"
          >
            <el-date-picker
              v-model={ this.subjectForm.signUpTime }
              type="daterange"
              range-separator="-"
              start-placeholder="年/月/日"
              end-placeholder="年/月/日"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm= { 24 } class="subject-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_3" />
              <span>课程时间</span>
            </>}}
            prop="subjectTime"
          >
            <el-date-picker
              v-model={ this.subjectForm.subjectTime }
              type="daterange"
              range-separator="-"
              start-placeholder="年/月/日"
              end-placeholder="年/月/日"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } sm= { 24 } class="subject-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_5" />
              <span>报名人数</span>
            </>}}
            prop="peopleNumber"
          >
            <el-input-number type="number" v-model={ this.subjectForm.peopleNumber } clearable placeholder="请输入报名人数" style="width: 100%" min={ 0 } />
          </el-form-item>
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_6" />
              <span>报名价格</span>
            </>}}
            prop="price"
          >
            <el-input-number v-model={ this.subjectForm.price } clearable placeholder="请输入报名价格" style="width: 100%" min={ 0 } />
          </el-form-item>
        </el-col>
        <el-col lg={ 16 } sm= { 24 } class="subject-detail-cell">
          <el-form-item prop="introduce" label="课程介绍" >
            <el-input type="textarea" v-model={ this.subjectForm.introduce } clearable style="width: 100%" />
          </el-form-item>
        </el-col>
      </el-row>
      {h(ImageCell, {
        ref: 'image_cell',
        coverImg: this.subjectForm.coverImg,
        mainImg: this.subjectForm.mainImg,
        otherImg: this.subjectForm.otherImg,
        sizeType: 'subject',
        onChangeImg: this.onChangeImg
      })}
      <div class="subject-detail-submit" onClick={() => debounceClick(this.submit)}>保存</div>
      <div class="subject-detail-top">
        <el-form-item label="月份" prop="monthId" >
          <el-cascader v-model={ this.subjectForm.monthId } props={ this.monthProps } style="width:100%" />
        </el-form-item>
      </div>
    </div>
  }

  submit(): void {
    const __form = this.$refs.form as any
    const __parent = this.$parent as Subject

    const __imageCell = this.$refs.image_cell as ImageCell
    __form.validate(v => {
      if (v) {
        const form = Object.assign({}, this.subjectForm, __imageCell.form)

        form.signUpStartTime = form.signUpTime?.length ? form.signUpTime[0] : undefined
        form.signUpEndTime = form.signUpTime?.length ? form.signUpTime[1] : undefined
        form.subjectStartTime = form.subjectTime?.length ? form.subjectTime[0] : undefined
        form.subjectEndTime = form.subjectTime?.length ? form.subjectTime[1] : undefined
        form.monthId = form.monthId && form.monthId[1]
        form.identity = form.identityList?.join(',')
        delete form.identityList

        delete form.signUpTime
        delete form.subjectTime
        __parent.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SmsSubjectAndChapterDto): void {
    delete form.id
    api.subjectSubjectAddSubject(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加课程成功'
      })
      this.goBack()
    })
  }

  submitEdit(form: SmsSubjectAndChapterDto): void {
    api.subjectSubjectAddSubject(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑课程成功'
      })
      this.goBack()
    })
  }

  selectionChange(row: Array<SmsSubjectChapterDtos>): void {
    this.ids = row
  }

  detailClose(): void {
    this.detailBool = false
    this.chapterType = ''
    this.chapterForm = {
      subjectId: '',
      title: '',
      time: '',
      integral: '',
      introduce: '',
      pic: ''
    }
  }

  chapterDetailDom(): JSX.Element {
    const slots = {
      // 对话框底部元素
      footer: () => (
        <span class="dialog-footer">
          <span class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</span>
          <span class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.chapterSubmit) }>确 定</span>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${this.chapterTitle}章节` }
        v-model={ this.detailBool }
        width="30%"
        before-close={ this.detailClose }
        v-slots={ slots }
      >
        <el-form
          ref="chapter"
          model={ this.chapterForm }
          rules={ this.chapterRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="名称" prop="title">
            <el-input v-model={ this.chapterForm.title } placeholder="请输入章节名称" clearable />
          </el-form-item>
          <el-form-item label="时间" prop="time">
            <el-date-picker
              v-model={ this.chapterForm.time }
              type="date"
              placeholder="请选择章节时间"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="积分" prop="integral">
            <el-input v-model={ this.chapterForm.integral } placeholder="请输入章节积分" clearable />
          </el-form-item>
          <el-form-item label="描述" prop="introduce">
            <el-input
              type="textarea"
              v-model={ this.chapterForm.introduce }
              placeholder="请输入章节描述"
              clearable
              maxlength={ 75 }
              show-word-limit
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item label="封面图" prop="pic">
              { this.loading
                ? <span class="el-form-tao-btn" >上传中...</span>
                : <span class="el-form-tao-btn" onClick={ this.uploadFile }>{ this.chapterForm.pic ? '重新上传' : '上传' }</span>}
              {this.chapterForm.pic && <span class="el-form-tao-view" onClick={this.imgView} >预览</span>}
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  uploadFile(): void {
    getFile(files => {
      if(!files.length) {
        return
      }
      const formData = new FormData()
      formData.append('file', files[0])
      this.loading = true
      api.commonOssUpload(formData).then(res => {
        this.chapterForm.pic = res.data
        ElNotification({
          title: '成功',
          type: 'success',
          message: `上传图片成功`
        })
        this.loading = false
      })
    }, 'SUBJECT_COVER', 'image/*')
  }

  imgView(): void {
    const url = this.chapterForm.pic as string
    this.url = url
    this.srcList = [url]
    this.imgViewerShow()
  }

  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }


  chapterSubmit(): void {
    const __chapterForm = this.$refs.chapter as any
    __chapterForm.validate(v => {
      if (v) {
        const form = Object.assign({}, this.chapterForm)
        this.chapterType === 'add'
          ? this.chapterSubmitAdd(form)
          : this.chapterSubmitEdit(form)
      } else {
        return false
      }
    })
  }

  chapterSubmitAdd(form: SmsSubjectChapterDtos): void {
    delete form.id
    this.subjectForm.smsSubjectChapterDtos?.unshift(form)
    this.detailClose()
  }

  chapterSubmitEdit(form: SmsSubjectChapterDtos): void {
    const arr = this.subjectForm.smsSubjectChapterDtos as Array<SmsSubjectChapterDtos>
    arr[this.detailIndex] = form
    this.detailClose()
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        data={ this.subjectForm.smsSubjectChapterDtos }
        onSelectionChange={ this.selectionChange }
      >
        <el-table-column type="selection" width="50" />
        {this.tableList.map(item => (
          <el-table-column
            prop={ item.prop }
            label={ item.label }
            align="center"
            v-slots={ item.slot }
          />
        ))}
      </el-table>
    )
  }

  @Watch('chapterType')
  chapterTilteChange(val: string): void {
    this.chapterTitle = typeWatch(val)
  }

  getMonthList(): void {
    this.monthProps = {
      lazy: true,
      lazyLoad: (node, resolve) => {
        const { level } = node
        if(level > 0) {
          api.selectGetMonth({year: node.data.label}).then(res => {
            this.monthList = res.data
            const nodes = res.data.map(item => ({
              value: item.id,
              label: item.fullName,
              leaf: level >= 1
            }))
            resolve(nodes)
          })
        } else {
          api.selectGetYear().then(res => {
            const nodes = res.data.map(item => ({
              value: `jinx_${item}`,
              label: item,
              leaf: level >= 1
            }))
            resolve(nodes)
          })
        }
      }
    }
  }
  created(): void {
    this.getMonthList()
  }

  render(): SubjectDetailElement {
    return <el-form
      ref="form"
      model={ this.subjectForm }
      class="subject"
      rules={ this.formRule }
    >
      <Card title={ `课程${ this.title }` } back onGoBack={ this.goBack } class="subject-card">
        {this.detailDom()}
      </Card>
      <Card title="章节信息">
        <div class="subject-chapter">
          <Button list={ this.buttonList } onClick={ this.buttonClick } />
          {this.tableDom()}
          {this.detailBool && this.chapterDetailDom()}
        </div>
      </Card>
      {this.imgViewerDom()}
    </el-form>
  }
}
