import api from "@/api";
import { Response } from "@/models/request";
import { getFile } from "@/utils";
import { Vue, Prop, Emit, Watch } from "vue-property-decorator";
import './index.scss'
export interface FormRule {
  coverImg?: string
  mainImg?: string
  otherImg?: string
}
/**
 * @description 照片墙
 * @author Jinx
 * @date 2021-08-30 16:36:00
 * @class ImageCell
 * @extends {Vue}
 */
class ImageCell extends Vue {

  @Prop(String) readonly coverImg!: string
  @Prop(String) readonly mainImg!: string
  @Prop(String) readonly otherImg!: string
  @Prop(Function) onChangeImg
  @Prop(String) readonly sizeType!: string

  @Emit('changeImg')

  private url = ''
  private srcList: Array<string> = []
  private coverImgBool = false
  private mainImgBool = false
  private otherImgBool = false
  private uploadNumber = 0

  public form: FormRule = {
    coverImg: '',
    mainImg: '',
    otherImg: ''
  }


  @Watch('coverImg', {deep: true, immediate: true})
  getCoverImg(val): void {
    if(val) {
      this.form.coverImg = val
    }
  }
  @Watch('mainImg', {deep: true, immediate: true})
  getMainImg(val): void {
    if(val) {
      this.form.mainImg = val
    }
  }
  @Watch('otherImg', {deep: true, immediate: true})
  getOtherImg(val): void {
    if(val) {
      this.form.otherImg = val
    }
  }

  /**
   * @description 删除图片
   * @author Jinx
   * @date 2021-08-30 09:24:03
   * @param {string} prop
   * @param {number} [ix]
   * @memberof ImageCell
   */
  imgSplict(prop: string, ix?: number): void {

    this.form = {
      coverImg: this.coverImg,
      mainImg: this.mainImg,
      otherImg: this.otherImg
    }

    if(String(ix)) {
      const arr = this.form.otherImg?.split(',') as Array<string>
      arr.splice(ix as number, 1)
      this.form[prop] = arr.join(',')
    } else {
      this.form[prop] = ''
    }

    this.$emit('changeImg', this.form)
  }

  showImg(url: string | Array<string>, i?: string): void {
    this.url = i || url as string
    this.srcList = url instanceof Array ? url : [url] as Array<string>
    this.imgViewerShow()
  }

  /**
   * @description 显示图片预览
   * @author Jinx
   * @date 2021-08-30 09:29:15
   * @memberof ImageCell
   */
  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  /**
   * @description 图片预览
   * @author Jinx
   * @date 2021-08-30 09:29:08
   * @returns {*}  {JSX.Element}
   * @memberof ImageCell
   */
  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  uploadImg(prop: string, size: string): void {
    this.form = {
      coverImg: this.coverImg,
      mainImg: this.mainImg,
      otherImg: this.otherImg
    }

    if(this[`${prop}Bool`]) {
      return
    }
    getFile((files) => {
      if(!files.length) {
        return
      }
      console.log(files)
      this[`${prop}Bool`] = true
      if(prop === 'otherImg') {
        // 多个上传
        const requestData = []
        files.forEach(item => {
          const formData = new FormData()
          formData.append('file', item)
          requestData.push(api.commonOssUpload(formData) as never)
        })
        Promise.all(requestData).then(res => {
          const imgArr: string[] = []
          this[`${prop}Bool`] = false
          res.forEach((i: Response) => {
            imgArr.push(i.data)
          })
          this.form.otherImg = this.form.otherImg ? `${this.form.otherImg},${imgArr.join(',')}` : imgArr.join(',')
          this[`${prop}Bool`] = false
          this.$emit('changeImg', this.form)
        })
      } else {
        // 单个上传
        const formData = new FormData()
        formData.append('file', files[0])
        api.commonOssUpload(formData).then(res => {
          this[`${prop}Bool`] = false
          this.form[prop] = res.data
          this.$emit('changeImg', this.form)
        })
      }
    }, size, 'image/*', prop === 'otherImg')
  }

  get getParameterType(): { cover: string, main: string, other: string } {
    switch(this.sizeType){
      case 'subject':
        return {
          cover: 'SUBJECT_COVER',
          main: 'SUBJECT_MAIN',
          other: ''
        }
      case 'activity':
        return {
          cover: 'ACTIVITY_COVER',
          main: 'ACTIVITY_MAIN',
          other: ''
        }
      case 'show':
        return {
          cover: 'SHOW_COVER',
          main: 'SHOW_MAIN',
          other: ''
        }
      case 'mall':
        return {
          cover: 'MALL',
          main: 'MALL',
          other: 'MALL'
        }
      default:
        return {
          cover: '',
          main: '',
          other: ''
        }
    }
  }

  resetImg(prop: string, size: string): void {
    this.form = {
      coverImg: this.coverImg,
      mainImg: this.mainImg,
      otherImg: this.otherImg
    }
    this.form[prop] = ''
    this.$emit('changeImg', this.form)
    this.uploadImg(prop, size)
  }

  render(): JSX.Element {
    return (
      <div class="image_cell">
        <div class="image_cell-cell">
          <span class="s-cell-title">封面</span>
          <el-form-item prop="coverImg">
            {this.coverImg
              ? <div class="s-cell-img">
                  <img class="s-cell-img-success pointer" src={ this.coverImg } alt="封面图片" />
                  <div class="s-cell-img-mark">
                    <i class="s-cell-img-close el-icon-remove" onClick={() => this.imgSplict('coverImg')} />
                    <span onClick={() => this.showImg(this.coverImg as string)}>预览</span>
                    <span onClick={this.resetImg.bind(this, 'coverImg', this.getParameterType.cover)}>重新上传</span>
                  </div>
                </div>
              : <div class="s-cell-content" onClick={ this.uploadImg.bind(this, 'coverImg', this.getParameterType.cover) }>
                  <i class={['s-cell-content-icon', this.coverImgBool ? 'el-icon-loading' : 'el-icon-picture' ]} />
                  <span class="s-cell-content-label">{ this.coverImgBool ? '上传中...' : '上传图片' }</span>
                </div>
            }
          </el-form-item>
        </div>
        <div class="image_cell-cells">
          <span class="s-cell-title">主图</span>
          <el-form-item prop="mainImg">
            {this.mainImg
              ? <div class="s-cell-img">
                  <img class="s-cell-img-success pointer" src={ this.mainImg } alt="主图图片" />
                  <div class="s-cell-img-mark">
                    <i class="s-cell-img-close el-icon-remove" onClick={() => this.imgSplict('mainImg')} />
                    <span onClick={() => this.showImg(this.mainImg as string)}>预览</span>
                    <span onClick={this.resetImg.bind(this, 'mainImg', this.getParameterType.main)}>重新上传</span>
                  </div>
                </div>
              : <div class="s-cell-content" onClick={ this.uploadImg.bind(this, 'mainImg', this.getParameterType.main) }>
                  <i class={['s-cell-content-icon', this.mainImgBool ? 'el-icon-loading' : 'el-icon-picture' ]} />
                  <span class="s-cell-content-label">{ this.mainImgBool ? '上传中...' : '上传图片' }</span>
                </div>
            }
          </el-form-item>
        </div>
        <div class="image_cell-cell">
          <span class="s-cell-title">其他</span>
          <el-form-item prop="otherImg">
            {this.otherImg && this.otherImg.split(',').map((i, ix) => (
              <div class="s-cell-img s-cell-imgs">
                <img class="s-cell-img-success pointer" src={ i } alt="其他图片" onClick={this.showImg.bind(this, this.otherImg?.split(',') as Array<string>, i)} />
                <i class="s-cell-img-close el-icon-remove" onClick={ this.imgSplict.bind(this, 'otherImg', ix) } />
              </div>
            ))
            }
            <div class="s-cell-content" onClick={ this.uploadImg.bind(this, 'otherImg', this.getParameterType.other) }>
              <i class={['s-cell-content-icon', this.otherImgBool ? 'el-icon-loading' : 'el-icon-picture' ]} />
              <span class="s-cell-content-label">{ this.otherImgBool ? '上传中...' : '上传图片' }</span>
            </div>
          </el-form-item>
        </div>
        {this.imgViewerDom()}
      </div>
    )
  }
}


export default ImageCell
