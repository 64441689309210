import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { SmsSubjectAndChapterDto } from "@/models/subject";
import { ButtonListRow, OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { parseTime, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { h } from "vue";
import { Options, Vue, Watch } from "vue-property-decorator";
import SubjectDetail from "./detail";
import './index.scss'

@Options({
  components: { SubjectDetail }
})
export default class Subject extends Vue {

  @Watch('type')
  typeChange(val: string): void {
    this.title = typeWatch(val)
    switch(val) {
      case 'add':
        break
      case 'edit':
        this.detailEdit()
        break
      default:
        this.getList()
    }
  }

  public title = ''

  private searchList: Array<SearchListRow> = []

  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'subjectAdd' },
  ]

  public classList: Array<OptionsRow> = []
  public teacherList: Array<OptionsRow> = []
  public memberLevelList : Array<OptionsRow> = []

  public statusList: Array<OptionsRow> = [
    { name: '未开启', value: 0 },
    { name: '报名中', value: 1 },
    { name: '已开始', value: 2 },
    { name: '已结束', value: 3 }
  ]

  private total = 0

  private detailRow: SmsSubjectAndChapterDto = {}

  public type = ''
  private searchObj: any = {}

  private listSum =0

  public tableData: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '时间', slot: {
      default: (scope: any): JSX.Element => <div>
        <span>{ parseTime(new Date(scope.row.subjectStartTime).getTime(), '{y}-{m}-{d}') }</span>
        <br />
        <span>{ parseTime(new Date(scope.row.subjectEndTime).getTime(), '{y}-{m}-{d}') }</span>
      </div>
    } },
    { label: '课程类型', slot: {
      default: (scope: any): JSX.Element => <span>{ this.classList.find(item => item.value === scope.row.typeId)?.name }</span>
    } },
    { label: '名称', prop: 'title' },
    { label: '章节数量', prop: 'chapterCount' },
    { label: '导师', slot: {
      default: (scope: any): JSX.Element => <span>{ this.teacherList.find(item => item.value === scope.row.tutorId)?.name }</span>
    } },
    { label: '状态', slot: {
      default: (scope: any): JSX.Element => <span class={['subject-status', `subject-status-${scope.row.status}`]}>{ this.statusList.find(item => item.value === scope.row.status)?.name }</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class={['span-button-primary', scope.row.loading && 'el-icon-loading']} onClick={ () => this.subjectEdit(scope.row.id, scope.$index) }>{ !scope.row.loading && '详情' }</span>
        <span class="span-button-danger" onClick={ () => this.subjectDelete(scope.row.id) }>删除</span>
      </div>
    } },
  ]

  buttonClick(click: string): void {
    this[click]()
  }

  subjectAdd(): void {
    this.tableData = []
    this.type = 'add'
  }

  async subjectEdit(id: number, index: number): Promise<any> {
    if(this.tableData[index].loading) {
      return
    }
    this.tableData[index].loading = true
    try {
      const res = await api.subjectSubjectGetSubjectById(id)

      const obj = Object.assign({}, res.data.smsSubject) as SmsSubjectAndChapterDto

      const { data } = await api.selectGetMonthById(obj.monthId as number)
      obj.monthId = [`jinx_${data.year}`, obj.monthId as number]
      obj.subjectTime = [obj.subjectStartTime as Date, obj.subjectEndTime as Date]
      obj.signUpTime = [obj.signUpStartTime as Date, obj.signUpEndTime as Date]
      obj.smsSubjectChapterDtos = res.data.chapter
      obj.identityList = obj.identity?.split(',').map(item => +item)
      this.detailRow = obj
      this.type = 'edit'
      this.tableData[index].loading = false
      this.tableData = []

    } catch (error: any) {
      this.tableData[index].loading = false
      return Error(error)
    }
  }

  detailEdit(): void {
    this.$nextTick(() => {
      const __subjectDetail = this.$refs.subjectDetail as SubjectDetail
      __subjectDetail.subjectForm = this.detailRow
    })
  }

  subjectDelete(id: number): void {
    ElMessageBox.confirm('确定删除该课程？', '删除课程', {
      type: 'warning'
    }).then(() => {
      api.subjectSubjectDeleteSubject([id]).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除课程成功'
        })
        this.listSum++
      })
    }).catch(() => {
      return false
    })
  }

  @Watch('listSum')
  watchList(sum: number): void {
    if(sum) {
      this.getList()
    }
  }

  getList(): void {
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow

      api.subjectSubjectGetSubjectList({ pageNum, pageSize, ...this.searchObj }).then(res => {
        this.tableData = res.data.list.map(item => ({...item, loading: false}))
        this.total = res.data.total
      })
    })
  }

  getData(): void {
    Promise.all([
      api.selectGetSysSelectTypeOption(3),
      api.subjectTutorGetTutorListSelect(),
      api.memberLevelList()
    ]).then(res => {
      this.classList = res[0].data.map(item => ({ name: item.name, value: item.value }))
      this.teacherList = res[1].data.map(item => ({ name: item.title, value: item.id }))
      this.memberLevelList = res[2].data.map(item => ({ name: item.name, value: item.id }))
      this.searchList = [
        { label: '系列课名称', type: 'input', submitKey: 'title' },
        { label: '课程类型', icon: 'svg_1', type: 'select', submitKey: 'typeId', options: this.classList },
        { label: '导师', icon: 'svg_2', type: 'select', submitKey: 'tutorId', options: this.teacherList },
        { label: '课程状态', type: 'select', options: this.statusList, submitKey: 'status' },
        { label: '时间', icon: 'svg_3', type: 'time', submitKey: ['subjectStartTime', 'subjectEndTime'] },
      ]
    })
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  onSearch(obj: never): void {
    this.searchObj = Object.assign({}, obj)
    this.getList()
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return this.type
      ? h(SubjectDetail, { title: this.title, ref: 'subjectDetail' })
      : <div class="subject">
        <Card title="课程查询" class="subject-card">
          <Search list={ this.searchList } class="subject-search" onSearch={ this.onSearch } />
        </Card>
        <Card title="系列课程">
          <div class="subject-search">
            <Button list={ this.buttonList } onClick={ this.buttonClick } />
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
          </div>
        </Card>
      </div>
  }

  mounted(): void {
    this.getList()
  }
}
